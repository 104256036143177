<template>
    <v-app-bar hide-on-scroll
        :prominent="true" 
        :dense="true" 
        class="mb-10">

        <v-row class="container flex-nowrap justify-center align-center align-self-center mx-auto px-0">

            <v-row class="flex-sm-nowrap justify-center align-center">
                
                <img v-if="signbookMerchantLogo" 
                    class="logo pr-3" 
                    :src="getMerchantLogo"
                    alt="Logo du marchand partenaire" />

                <span v-if="signbookMerchantLogo" 
                    class="d-none d-sm-flex text-h6 font-weight-bold grey--text text--darken-2 pr-3">
                    avec
                </span>

                <img src="/img/logo_floa.svg" 
                    class="logo"
                    alt="Logo de Floa" 
                />
            </v-row>

            <p v-if="isReminderVisible"
                class="d-none d-sm-block text-body-2 grey--text text--darken-1 text-justify mb-0 ml-5">
                Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.
            </p>
        </v-row>

    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import { log } from './../../helper';

    export default {
        name: 'HeaderLayout',
        props: {
            signbookMerchantLogo: {
                type: String,
                default: ''
            },
            isReminderVisible: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                floaLogo: require('../../../public/img/logo_floa.svg')
            }
        },
    computed: {
        ...mapGetters([
                'getContext',
            ]),
            getMerchantLogo: function () 
            {
                const logo = this.signbookMerchantLogo && this.signbookMerchantLogo !== ''
                    ? 'data:image/image/png;base64,' + this.signbookMerchantLogo
                    : undefined

                log(this.getContext, `Header component is displayed. Merchant logo is ${logo ? "displayed" : "undefined" }`) 
                
                return logo
            }
        }
    }
</script>

<style>

.logo {
    max-height: 55px;
}

.theme--light.v-toolbar.v-sheet {
    background-color: #FFFFFF !important;
}
    
</style>