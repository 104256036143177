<template>
    <div class="legal-notice__wrapper">
        <div class="legal-notice">
            <span class="legal-notice__title">Mentions légales :</span>
            Pour plus d’informations sur le traitement de vos données personnelles,
            consulter la
            <a href="https://www.floabank.fr/images/pdf/Politique_de_confidentialit_et_cookie.pdf"
               target="_blank" @click="openLegalNotices()">
                Politique de confidentialité et cookies
            </a>
            de FLOA.
            <div>
                Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de
                remboursement avant de vous engager.
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { log } from './../../helper';

    export default {
        name: 'LegalNotice',
        computed: {
            ...mapGetters([
                    'getContext',
            ] )
        },
        mounted() 
        {
            log(this.getContext, "Legal notices component is displayed.") 
        },
        methods: {
            openLegalNotices()
            {
                log(this.getContext, "User clicked on legal notices show button.");
            }
        }
    }
</script>
<style>
    .legal-notice__wrapper {
        grid-area: footer;
        border-radius: 10px;
        font-size: 12px;
        padding: 0 20px;
    }

    @media only screen and (min-width: 800px) {
        .legal-notice__wrapper {
            padding: 0;
        }
    }

    @media only screen and (min-width: 1200px) {
        .legal-notice__wrapper {
            padding: 0;
        }
    }

    .legal-notice {
        border-radius: 10px;
        background-color: #f8f8f8;
        color: #656974;
        padding: 10px 15px;
    }
       
    .legal-notice__title {
        font-weight: 600;
    }

    a {
        text-decoration: underline;
        color: inherit;
    }
</style>