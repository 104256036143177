<template>
    <v-col cols="12" sm="12" md="12" lg="6" id="sign-block">
        <div id="sign-block-title" class="mb-3">
           <p>Dernière étape, signature de votre contrat</p> 
        </div>
        <div class="mb-5">
            <p class="font-weight-bold mb-0">{{ gender }}. {{ lastName }},</p>
            <p class="mb-3">votre demande de financement est validée.</p>
            <p>Nous vous invitons à prendre connaissance de votre contrat puis suivre les instructions pour le signer afin de finaliser votre crédit.</p>
        </div>
        <p class="text-caption">
            Si le contrat ci-dessous ne s'affiche pas, veuillez actualiser cette page ou <a href="#" @click="reloadPage">cliquer ici</a>
        </p>
         <div id="sign-block-iframe">
            <iframe 
                id="signbook" 
                scrolling="no" 
                frameBorder="no" 
                width="100%" 
                allow="microphone; camera"
                @load="onload()">
            </iframe>
            <Loader v-if="isLoading" :is-signbook-processing="isLoading"/>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { log } from './../../helper';
import Loader from './../Loader.vue';

export default {
    name: 'Contract',
    components: {
        Loader,
    },
    props: {
        gender: {
            type: String,
            default: ''
        },
        lastName: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            shouldLogOnLoad: true
        }
    },
    computed: {
        ...mapGetters( [
            'getContext',
        ] ),
    },
    mounted()
    {
        log(this.getContext, "Contract header is displayed.");
    },
    methods: {
        onload()
        {
            if (this.shouldLogOnLoad)
            {
                log(this.getContext, "Contract content is loading.");
                this.shouldLogOnLoad = false;
            }
        },
        reloadPage()
        {
            log(this.getContext, "User clicked on reload contract button.");
            window.location.reload();
        }
    },
} 
</script>

<style>
#sign-block {
        text-align: center;
        background-color: #FFF;
        border-radius: 10px;
        border-top: 10px solid #009FFF;
        padding-left: 35px;
        padding-right: 35px;
    }

    #sign-block-title {
        font-size: 1.3em;
        color: #009FFF;
        font-weight: bold;
    }

    #sign-block-iframe {
        min-height: 300px;
        background-color: #f4f5f9;
        position: relative;
    }
</style>